<template>
  <div class='hot-news-note-list'>
    <!-- 24小时 -->
    <div v-for='(item,index) in hotDataInfo' :key='index'>
      <div :class="imgsUrl + index" v-if='hotDataInfo[index].list.length'></div>
      <hot-list :hotDataInfo='hotDataInfo[index]' :hotFlag='hotFlag' :index='index' @changeHotList='changeHotList'></hot-list>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HotNewsNote',
  components: {
    'hot-list': () => import(/* webpackChunkName: "home" */ '@/components/HotList')
  },
  props: {
    hotDataInfo: Array,
    hotFlag: Number
  },
  computed: {
    imgsUrl() {
      if (this.hotFlag === 3) {
        return 'hot-imgs'
      } else {
        return 'hot-imgs-note'
      }
    }
  },
  methods: {
    changeHotList(data,index) {
      this.$emit('changeHotList',data,index)
    }
  }
}
</script>

<style scoped lang='less'>
  .hot-news-note-list {
    width: 100%;
    box-sizing: border-box;
    padding: 0 17px 50px 11px;
    .hot-imgs0 {
      width: 100%;
      height: 26px;
      margin-bottom: 8px;
      background: url("https://cdn.9kd.com/kdnet/hot_news_24.png") no-repeat left center;
      background-size: contain;
    }
    .hot-imgs-note0 {
      width: 100%;
      height: 26px;
      margin-bottom: 8px;
      background: url("https://cdn.9kd.com/kdnet/hot_note_24.png") no-repeat left center;
      background-size: contain;
    }
    .hot-imgs1 {
      width: 100%;
      height: 26px;
      margin-bottom: 8px;
      background: url("https://cdn.9kd.com/kdnet/hot_news_7.png") no-repeat left center;
      background-size: contain;
    }
    .hot-imgs-note1 {
      width: 100%;
      height: 26px;
      margin-bottom: 8px;
      background: url("https://cdn.9kd.com/kdnet/hot_note_7.png") no-repeat left center;
      background-size: contain;
    }
    .hot-imgs2 {
      width: 100%;
      height: 26px;
      margin-bottom: 8px;
      background: url("https://cdn.9kd.com/kdnet/hot_news_30.png") no-repeat left center;
      background-size: contain;
    }
    .hot-imgs-note2 {
      width: 100%;
      height: 26px;
      margin-bottom: 8px;
      background: url("https://cdn.9kd.com/kdnet/hot_note_30.png") no-repeat left center;
      background-size: contain;
    }
  }
</style>
